<script setup>
import Alert from "@/Components/Alert.vue";
import Button from "@/Components/Button.vue";
import Google from "@/Components/Icons/Google.vue";
import TextInput from "@/Components/TextInput.vue";
import { useForm } from "@inertiajs/vue3";
import Rubbon from "./Rubbon.vue";

const form = useForm({
    email: "",
    password: "",
    password_confirmation: "",
    terms: false,
});

const submit = () => {
    const $route = route("register");
    form.transform((data) => ({
        ...data,
        remember: form.remember ? "on" : "",
    })).post($route, {
        onFinish: () => {
            form.reset("password"), form.reset("password_confirmation");
        },
    });
};
</script>
<template>
    <div
        class="bg-gray-100 lg:min-h-[calc(100vh-5rem)] py-24 lg:py-24 flex items-center bg-cover bg-center bg-[url('@assets/images/home/hero.jpg')]"
    >
        <div
            class="container flex lg:flex-row flex-col items-center 2xl:gap-64 xl:gap-20 gap-6"
        >
            <div class="flex-1">
                <div class="flex flex-col gap-4 text-white">
                    <h2
                        class="text-base bg-primary/75 px-4 py-2 inline-block w-fit font-medium rounded-full"
                    >
                        Le site libertin
                    </h2>
                    <h1 class="lg:text-5xl text-3xl font-bold leading-none">
                        PlaisirOnline
                    </h1>

                    <p class="text-xl font-medium">
                        Profitez de PlaisirOnline pendant 1 mois gratuit en vous inscrivant.
                    </p>
                    <div class="lg:mb-24"></div>

                    <!-- <div class="flex flex-col gap-4 mt-4">
                        <p class="text-lg">Dernier inscrit</p>
                        <div
                            class="flex items-center gap-2 rounded-full bg-white p-2 w-fit pr-8 text-dark"
                        >
                            <img
                                src="https://randomuser.me/api/portraits/women/26.jpg"
                                alt="Dernier inscrit"
                                class="w-12 h-12 rounded-full"
                            />
                            <div>
                                <h3 class="font-semibold">
                                    Christophe (35ans) - Rennes
                                </h3>
                                <p class="text-sm">Cherche couples</p>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="flex-1 w-full">
                <div class="card relative">
                   <!-- <Rubbon class="absolute w-64 h-64 top-0 left-0 -translate-x-[68%] lg:block hidden z-[100]" />-->
                    <div
                        class="card-header bg-primary-gradient p-5 text-white rounded-t-super-large home-register-card relative"
                    >

                        <div class="md:px-10 px-4 pt-2">
                            <h3
                                class="md:text-3xl text-2xl leading-8 font-bold"
                            >
                                Profitez de 1 mois gratuits en vous inscrivant avec le code promo 
                                <span class="text-white">SEB2024*</span>
                            </h3>
                            <p class="font-medium mt-2">
                                Prêt(e) à vivre de nouvelles expériences ?
                                Rejoins-nous et bénéficie de 1 mois d'abonnement
                                offert !
                            </p>
                            <p class="font-bold italic mt-2">
                                *Offre non cumulable avec les autres offres en cours.
                            </p>
                        </div>
                    </div>
                    <div class="p-5 bg-white rounded-b-super-large">
                        <div class="md:px-10 px-4">
                            <form @submit.prevent="submit" class="py-4">
                                <transition name="fade">
                                    <Alert
                                        variant="error"
                                        title="Ooops!"
                                        class="mt-2 mb-6"
                                        v-if="
                                            Object.keys(form.errors).length > 0
                                        "
                                    >
                                        <template v-for="error in form.errors">
                                            <div>{{ error }}</div>
                                        </template>
                                    </Alert>
                                </transition>
                                <div class="input-group">
                                    <TextInput
                                        label="Adresse email"
                                        icon="mail"
                                        required
                                        container-class="w-full"
                                        v-model="form.email"
                                        placeholder="Adresse email"
                                        autocomplete="email"
                                        name="email"
                                    />
                                </div>
                                <div class="input-group">
                                    <TextInput
                                        label="Mot de passe"
                                        icon="lock"
                                        required
                                        container-class="w-full"
                                        v-model="form.password"
                                        placeholder="Mot de passe"
                                        type="password"
                                        autocomplete="current-password"
                                        name="password"
                                    />
                                </div>
                                <div class="input-group">
                                    <TextInput
                                        label="Confirmer le mot de passe"
                                        icon="lock"
                                        container-class="w-full"
                                        v-model="form.password_confirmation"
                                        placeholder="Confirmer le mot de passe"
                                        type="password"
                                        required
                                        autocomplete="current-password"
                                        name="password_confirmation"
                                    />
                                </div>

                                <Button
                                    class="w-full"
                                    type="submit"
                                    :class="{
                                        'opacity-25': form.processing,
                                    }"
                                    :disabled="form.processing"
                                >
                                    S'inscrire
                                </Button>
                                <div
                                    class="flex items-center justify-between my-6"
                                >
                                    <span
                                        class="w-1/5 border-b border-dark/4 lg:w-1/4"
                                    ></span>
                                    <p
                                        class="text-xs text-center text-gray-500 uppercase"
                                    >
                                        ou
                                    </p>
                                    <span
                                        class="w-1/5 border-b border-dark/4 lg:w-1/4"
                                    ></span>
                                </div>
                                <div class="input-group">
                                    <a :href="route('auth.google')">
                                        <Button
                                            type="button"
                                            variant="light"
                                            class="w-full"
                                        >
                                            <span
                                                class="flex items-center gap-2 justify-center"
                                            >
                                                <Google />
                                                <span class="relative top-0.5">
                                                    S'inscrire avec Google
                                                </span>
                                            </span>
                                        </Button>
                                    </a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
